@charset "UTF-8";
@use "sass:math";

//==========================================================
// base
// 基本CSS
// - base
//==========================================================
@use "base/variable" as *;
@use "base/icomoon-style";
@use "base/mixin" as *;;
@use "base/keyframes";

//==========================================================
// Icons Fonts
//==========================================================
@font-face {
  font-family: 'lc-icons';
  src:  url('../fonts/lc-icons.eot?dzwa3x');
  src:  url('../fonts/lc-icons.eot?dzwa3x#iefix') format('embedded-opentype'),
    url('../fonts/lc-icons.ttf?dzwa3x') format('truetype'),
    url('../fonts/lc-icons.woff?dzwa3x') format('woff'),
    url('../fonts/lc-icons.svg?dzwa3x#lc_icons') format('svg');
  font-weight: normal;
  font-style: normal;
}

//==========================================================
// Reset LaunchCart Components
//==========================================================
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  height: 100%;
  font-size: 10px;
}
body {
  min-height: 100%;
  background: $c_base;
  font-size: $f_size_pc;
  font-family: $f_base_all;
  line-height: 1.75;
  color: $c_text;
  -webkit-text-size-adjust: 100%;
  overflow-x: hidden;
  position: relative;
  &:lang(en),
  &:lang(zh-cn),
  &:lang(zh-tw),
  &:lang(zh-hk) {
    font-family: $f_base_all;
  }
  @include media(sp) {
    font-size: $f_size_sp;
  }
}

*[class^="lc-"] {
  margin: 0;
  padding: 0;
}

img {
  .hasImg > & {
    opacity: 0;
  }
  .hasImg.is-loaded > & {
    opacity: 1;
  }
  &[class^="lc-"],
  [class^="lc-"] > & {
    max-width: 100%;
    vertical-align: top;
  }
}

i {
  &[class^="lc-"],
  [class^="lc-"] > & {
    font-style: normal;
  }
}

.only-pc {
  &[class^="lc-"],
  [class^="lc-"] > & {
    @include media(sp) {
      display: none !important;
    }
  }
}
.only-sp {
  &[class^="lc-"],
  [class^="lc-"] > & {
    @include media(tab-min) {
      display: none !important;
    }
  }
}
.only-sp-land {
  &[class^="lc-"],
  [class^="lc-"] > & {
    display: none !important;
    @include media(360px) {
      display: block !important;
    }
  }
}
.only-ss {
  &[class^="lc-"],
  [class^="lc-"] > & {
    @include media(ss-min) {
      display: none !important;
    }
  }
}

.is-required {
  &[class^="lc-"],
  [class^="lc-"] > & {
    &::after {
      content: "*";
      margin: 0 0.5em;
      color: $c_error;
    }
  }
}

a {
  &[class^="lc-"],
  [class^="lc-"] > & {
    color: inherit;
    text-decoration: none;
    transition: 0.3s ease 0s;
    &[href^="tel"] {
      color: inherit;
    }
    @include media(tab-min) {
      img {
        transition: 0.3s ease 0s;
      }
      &[href]:hover {
        opacity: 0.7;
        img {
          opacity: 0.7;
        }
      }
    }
  }
}

ol, ul {
  &[class^="lc-"] {
    list-style: none;
  }
}

h1, h2, h3, h4, h5, h6, strong, em {
  &[class^="lc-"] {
    font-size: 100%;
    font-weight: inherit;
  }
}
h1, h2, h3, h4, h5, h6 {
  &[class^="lc-"] {
    line-height: 1.5;
  }
}
input, textarea,
select {
  &[class^="lc-"],
  [class^="lc-"] > & {
    margin: 0;
    font-size: 16px;
  }
}

button {
  &[class^="lc-"],
  [class^="lc-"] > & {
    padding: 0;
    border: none;
    overflow: visible;
    background: none;
    vertical-align: top;
    font: inherit;
    color: inherit;
    cursor: pointer;
    outline: none;
    -webkit-appearance: none;
    transition: 0.3s ease 0s;
    @include media(tab-min) {
      img {
        transition: 0.3s ease 0s;
      }
      &[href]:hover {
        opacity: 0.7;
        img {
          opacity: 0.7;
        }
      }
    }
  }
}
input {
  &[class^="lc-"],
  [class^="lc-"] > &,
  [class^="lc-"] > .selectable > & {
    &[type="text"],
    &[type="password"],
    &[type="email"],
    &[type="tel"],
    &[type="search"],
    &[type="date"] {
      height: 32px;
      padding: 0 10px;
      border: none;
      border-radius: 4px;
      box-shadow: none;
      -webkit-appearance: none;
      &[size="60"] {
        width: 100%;
      }
      &::placeholder {
        color: $c_text_gray;
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      position: absolute;
      opacity: 0.01;
      + label,
      + * .c-inputUI {
        display: inline-block;
        margin: 5px 15px 5px 0;
        position: relative;
        user-select: none;
        &::before {
          margin-right: 5px;
          transition: 0.3s;
          position: absolute;
          left: 0;
          top: 50%;
          transform: translate(0, -50%);
        }
      }
      + * .c-inputUI {
        display: inline;
        margin: 0;
        &::before {
          display: inline-block;
        }
      }
      &:checked {
        + label,
        + * .c-inputUI {
          &::before {
            border-color: $cRed;
          }
        }
      }
    }
    &[type="checkbox"] {
      + label {
        padding-left: 1.5em;
        @extend .lc-icon;
        @extend .lc-icon--check;
        &::before {
          border: solid 1px $c_text_gray;
          color: transparent;
        }
      }
      &:checked {
        + label {
          &::before {
            color: $cRed;
          }
        }
      }
    }
    &[type="radio"] {
      + label {
        padding-left: 1.25em;
        &::before {
          content: "";
          width: 1em;
          height: 1em;
          border: solid 1px $c_text_gray;
          border-radius: 50%;
          background: $c_base;
        }
      }
      &:checked {
        + label {
          &::before {
            box-shadow: inset 0 0 0 2px $c_base;
            background: $cRed;
          }
        }
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      + * .c-inputUI--checkbox {
        padding-left: 1.5em;
        @extend .lc-icon;
        @extend .lc-icon--check;
        &::before {
          border: solid 1px $c_text_gray;
          color: transparent;
        }
      }
      &:checked {
        + * .c-inputUI--checkbox {
          &::before {
            color: $cRed;
          }
        }
      }
    }
    &[type="checkbox"],
    &[type="radio"] {
      + * .c-inputUI--radio {
        padding-left: 1.25em;
        &::before {
          content: "";
          width: 1em;
          height: 1em;
          border: solid 1px $c_text_gray;
          border-radius: 50%;
          background: $c_base;
        }
      }
      &:checked {
        + * .c-inputUI--radio {
          &::before {
            box-shadow: inset 0 0 0 2px $c_base;
            background: $c_main;
          }
        }
      }
    }
  }
}
textarea {
  &[class^="lc-"],
  [class^="lc-"] > & {
    width: 100%;
    min-height: 200px;
    padding: 7px 10px;
    border: none;
    border-radius: 4px;
    box-shadow: none;
    -webkit-appearance: none;
    @include media(tab) {
      min-height: 120px;
    }
  }
}

.error {
  color: $c_error;
}

.lc-root {

  //==========================================================
  // Icons
  //==========================================================
  [class^="lc-icon-"],
  [class*="lc-icon-"],
  .lc-icon {
    &::before {
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'lc-icons' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
    
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
  .lc-icon {
    &--arrow {
      &Down:before {
        content: "\e900";
      }
      &Left:before {
        content: "\e901";
      }
      &Right:before {
        content: "\e902";
      }
      &Up:before {
        content: "\e903";
      }
    }
    &--arrow2 {
      &Down:before {
        content: "\e904";
      }
      &Left:before {
        content: "\e905";
      }
      &Right:before {
        content: "\e906";
      }
      &Up:before {
        content: "\e907";
      }
    }
    &--cart:before {
      content: "\e908";
    }
    &--check:before {
      content: "\e909";
    }
    &--close:before {
      content: "\e90a";
    }
    &--dollar:before {
      content: "\e90b";
    }
    &--globe:before {
      content: "\e90c";
    }
    &--heart:before {
      content: "\e90d";
      .is-active & {
        content: "\e915";
      }
    }
    &--heartActive:before {
      content: "\e915";
    }
    &--menu:before {
      content: "\e90e";
    }
    &--plus:before {
      content: "\e90f";
    }
    &--search:before {
      content: "\e910";
    }
    &--sns{
      &Facebook:before {
        content: "\e911";
      }
      &Twitter:before {
        content: "\e912";
      }
    }
    &--star:before {
      content: "\e913";
    }
    &--user:before {
      content: "\e914";
    }
  }
  .is-active {
    .lc-icon {
      &--heart:before {
        content: "\e915";
      }
    }
  }

  //==========================================================
  // Heading
  //==========================================================
  .lc-heading {
    &--2 {
      margin-top: 2em;
      margin-bottom: 1em;
      font-size: 2rem;
      font-weight: bold;
      line-height: 1.33;
      @include media(tab) {
        margin-bottom: 0.75em;
        font-size: 2rem;
        line-height: 1.25;
      }
    }
    &--3 {
      margin-top: 1.5em;
      margin-bottom: .75em;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.25;
      @include media(tab) {
        margin-bottom: 0.5em;
        font-size: 1.6rem;
      }
    }
  }

  //==========================================================
  // Text Align
  //==========================================================
  .lc-align {
    &--left {
      text-align: left !important;
    }
    &--right {
      text-align: right !important;
    }
    &--center {
      text-align: center !important;
    }
  }

  //==========================================================
  // Buttons
  //==========================================================
  .lc-button {
    &__icon {
      display: inline-block;
      font-size: 2rem;
      &:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-left: 5px;
      }
    }
    &__label {
      white-space: nowrap;
    }
    &--submit {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      min-width: 240px;
      height: 50px;
      padding: 0 10px;
      margin-bottom: 15px;
      border: solid 2px $cBlack;
      background: $cBlack;
      color: $c_text_accent;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      &:disabled {
        border: solid 2px $c_text_gray;
        background: $c_text_gray;
        color: $c_text_main;
        cursor: not-allowed;
      }
      @include media(tab-min) {
        &:not(:disabled):hover {
          background: $c_text_accent;
          color: $cBlack;
          opacity: 1;
        }
      }
    }
    &--action {
      border: solid 2px $cBlack;
      background: $cBlack;
      color: #fff;
      &.is-disabled {
        border: solid 2px $c_text_gray;
        background: $c_text_gray;
        color: $c_text_main;
        cursor: not-allowed;
      }
      @include media(tab-min) {
        &:not(.is-disabled):hover {
          color: #000;
          background: #fff;
        }
      }
    }
    &--onlyIcon {
      width: 50px;
      min-width: 0;
      > .lc-button__label {
        display: none;
      }
      > .lc-button__icon {
        margin: 0;
      }
    }
    &--cancel {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      color: $cBlack;
      font-size: 1.6rem;
      font-weight: bold;
      line-height: 1;
      padding: 0 1em;

      &:hover {
        opacity: 1;
        //background-color: $c_text_gray;
        color: $c_text_gray;
      }

    }
    &--cancel.privacy_btn{
      &:hover {
        background-color: transparent;
        color: $c_text_gray;
      }
    }
    &--delete,
    &--edit {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      height: 32px;
      padding: 0 10px;
    }
    &--delete {
      border: solid 1px #FF9B9B;
      color: $c_error;
      background-color: $c_base;
      @include media(tab-min) {
        &:not(.is-disabled):hover {
          background: #FF9B9B;
          border-color: $c_error;
          color: $c_base;
          opacity: 1;
        }
      }
    }
    &--edit {
      border: solid 1px $c_text_gray;
      @include media(tab-min) {
        &:not(.is-disabled):hover {
          background: $c_text_gray;
          color: $c_base;
          opacity: 1;
        }
      }
    }
  }

  //==========================================================
  // Modal
  //==========================================================
  .lc-modal {
    display: none;
    &.is-active {
      position: fixed;
      top: 0;
      left: 0;
      padding: 3rem;
      width: 100%;
      height: 100vh;
      justify-content: center;
      align-items: center;
      text-align: center;
      z-index: 13;
      display: flex;
      @include media(sp){
        margin-top: 10vw;
        padding: 1rem;
      }
    }
    &__bg {
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.75);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 12;
      visibility: hidden;
      opacity: 0;
      transition: 0.5s;
      &.js-lc--closeModal {
        cursor: pointer;
      }
    }
    &__body {
      width: 480px;
      max-width: calc(100% - 30px);
      padding: 30px;
      background: $c_base;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);
      //position: absolute;
      // left: 50%;
      // top: 60px;
      z-index: 13;
      visibility: hidden;
      opacity: 0;
      // transition: 0.5s;
      // transform: translate(-50%, 0);
      @include media(tab) {
        padding: 15px;
      }
      &--wide {
        width: 640px;
      }
    }
    &__title {
      margin-bottom: 1em;
      font-size: 2.4rem;
      font-weight: bold;
      line-height: 1.25;
      text-align: center;
      @include media(tab) {
        font-size: 2em;
        margin-bottom: 20px;
      }
    }
    &.is-active {
      > .lc-modal {
        &__bg,
        &__body {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    &__buttons {
      text-align: center;
      button {
        margin: 20px auto 0;
        border: 1px solid $cBlack;
      }
    }    
    .modal_notice{
      a,
      button{
      width: 200px;
      display: inline-block;
      background: $cBlack;
      color: #fff;
      padding: 1.5rem 1rem;
      margin: 2rem 0;
      font-size: 1.6rem;
      line-height: 1;
      height: auto;
      font-weight: 700;
      border: 1px solid $cBlack;
    }
    a{
      margin-right: 10px;
      @include media(sp){
        margin-right: 0;
      }
    }
    button{
      background: #fff;
      color: $cBlack;
      @include media(sp){
        margin-top: 0;
      }
    }
  }
    
  }
  .lc-selectModalSettings {
    &__item {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
    }
    &__label {
      width: 90px;
    }
  }

  //==========================================================
  // Forms
  //==========================================================
  .lc-selectBox {
    display: inline-block;
    border: solid 1px $c_border;
    border-radius: 4px;
    font-size: 1.6rem;
    position: relative;
    background-color: $c_base;
    @extend .lc-icon;
    @extend .lc-icon--arrow2Down;
    &__select {
      width: 240px;
      height: 32px;
      padding: 0 30px 0 10px;
      border: none;
      background: transparent;
      position: relative;
      z-index: 1;
      appearance: none;
      &::-ms-expand {
        display: none;
      }
    }
    &::before {
      color: $c_text_gray;
      position: absolute;
      right: 10px;
      top: 50%;
      z-index: 0;
      transform: translate(0, -50%);
    }
  }
  .lc-form {
    max-width: 640px;
    margin: auto 0;
    &__item {
      margin-bottom: 15px;
      h4 {
        text-align: left;
        margin-left: 10px;
        min-height: 24px;
      }
    }
    &__columns {
      display: flex;
      align-items: center;
    }
    &__column {
      flex-grow: 1;
      margin-right: 15px;
      &:last-child {
        margin-right: 0;
      }
      &--natural {
        flex-grow: 0;
      }
    }
    &__label {
      display: block;
      position: relative;
      &--half {
        width: 50%;
      }
      &--select {
        display: inline-block;
        @extend .lc-icon;
        @extend .lc-icon--arrow2Down;
        &::before {
          font-size: 1.6rem;
          position: absolute;
          right: 10px;
          top: 68%;
          z-index: 0;
          transform: translate(0, -50%);
          z-index: 1;
        }
        select[data-size] {
          @for $i from 6 through 24 {
            &[data-size="#{ $i * 10 }"] {
              width: #{ $i * 10 }px;
            }
          }
        }
      }
      &--plain {
        .lc-form {
          &__title:not(form h4) {
            padding: 0;
            margin-bottom: 0.5em;
            position: static;
            transform: none;
          }
        }
      }
      &.is-focused {
        .lc-form {
          &__title {
            color: $c_text;
            font-weight: bold;
          }
        }
        input {
          &[type="text"],
          &[type="password"],
          &[type="email"],
          &[type="tel"],
          &[type="date"] {
            border-color: $cBlack;
          }
        }
        select,
        textarea {
          border-color: $cBlack;
        }
        &.is-errored {
          input {
            &[type="text"],
            &[type="password"],
            &[type="email"],
            &[type="tel"],
            &[type="date"] {
              border-color: $c_error;
            }
          }
          select,
          textarea {
            border-color: $c_error;
          }
        }
      }
      &.is-errored {
        .lc-form{
          &__title {
            color: $c_error;
          }
        }
        input {
          &[type="text"],
          &[type="password"],
          &[type="email"],
          &[type="tel"],
          &[type="date"] {
            border-color: $c_error;
          }
        }
        select,
        textarea {
          border-color: $c_error;
        }
      }
    }
    &__title:not(form h4) {
      padding: 0 5px;
      font-size: 1.4rem;
      font-weight: bold;
      line-height: 1.2;
      white-space: nowrap;
      position: absolute;
      left: 10px;
      top: -0.1em;
      z-index: 1;
      transform: translate(0, -100%);
      transition: 0.3s;
      &.is-required {
        &::after {
          margin-right: 0;
        }
      }
    }
    &__detail {
      position: relative;
      z-index: 0;
      input {
        &[type="text"],
        &[type="password"],
        &[type="email"],
        &[type="tel"],
        &[type="date"] {
          width: 100%;
          height: 44px;
          padding: 0 15px;
          border: solid 1px $c_text_gray;
          border-radius: 4px;
          vertical-align: top;
          outline: none;
          transition: 0.3s;
        }
      }
      select {
        display: flex;
        align-items: center;
        height: 44px;
        padding: 0 30px 0 15px;
        border: solid 1px $c_text_gray;
        border-radius: 4px;
        background: transparent;
        vertical-align: top;
        outline: none;
        position: relative;
        z-index: 2;
        transition: 0.3s;
        appearance: none;
        background-color: $c_base;
        &::-ms-expand {
          display: none;
        }
      }
      textarea {
        width: 100%;
        padding: 15px;
        border: solid 1px $c_text_gray;
        border-radius: 4px;
        vertical-align: top;
        outline: none;
        transition: 0.3s;
      }
      > .error {
        display: block;
        padding: 5px 13px;
        color: $c_error !important;
        font-size: 1.4rem !important;
      }
      &--stars {
        display: flex;
        align-items: center;
        line-height: 1;
      }
    }
    &__note {
      margin-top: 5px;
      a,
      button {
        color: $c_main;
        font-weight: bold;
      }
      &.error,
      &.is-error {
        padding: 5px 13px;
        font-size: 1.4rem!important;
      }
    }
    small {
      display: inline-block;
    }
    @include media(sp) {
      &__label {
        &--select {
          &::before {
            font-size: 1.4rem;
          }
        }
      }
      &__title {
        font-size: 1.2rem;
      }
      &__detail {
        > .error {
          font-size: 1.2rem !important;
        }
      }
      &__note {
        &.error,
        &.is-error {
          font-size: 1.2rem!important;
        }
      }
    }
  }

  //==========================================================
  // Table
  //==========================================================
  .lc-table {
    font-size: 1.4rem;
    border-collapse: collapse;
    border-spacing: 0;
    font-size: 100%;
    &.is-align--center {
      margin: 0 auto;
    }
    th {
      text-align: left;
    }
    thead {
      border-bottom: solid 1px $c_border;
      line-height: 1.25;
      th,
      td {
        padding: 0 30px 10px 0;
        vertical-align: bottom;
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
      th {
        font-weight: bold;
      }
    }
    tbody {
      &:first-child {
        border-top: solid 1px $c_border;
      }
      tr {
        &:nth-child(even) {
          background: #fff;
        }
        &:last-child {
          border-bottom: solid 1px $c_border;
        }
      }
      th,
      td {
        padding: 5px 30px 5px 0;
        &:first-child {
          padding-left: 10px;
        }
        &:last-child {
          padding-right: 10px;
        }
      }
      th {
        font-weight: bold;
        line-height: 1.25;
        white-space: nowrap;
      }
      td {
        line-height: 1.5;
      }
    }
    &--wide {
      width: 100%;
      tbody {
        th,
        td {
          padding: 10px 30px 10px 0;
        }
      }
    }
    &--numbers {
      width: 100%;
      margin: 30px auto 15px;
      thead {
        th {
          text-align: right;
        }
      }
      tbody {
        th, td {
          text-align: right;
        }
        th {
          width: 100%;
          font-weight: normal;
        }
        td {
          padding-right: 15px;
          white-space: nowrap;
          > em {
            font-weight: bold;
          }
        }
      }
      .lc-heading--3 + & {
        margin: 0 0 45px;
      }
    }
    &--points {
      width: 100%;
      thead {
        th {
          &:last-child {
            text-align: right;
          }
        }
      }
      tbody {
        th {
          font-weight: normal;
        }
        td {
          > em {
            font-weight: bold;
          }
          &:last-child {
            text-align: right;
          }
        }
      }
    }

    @include media(tab) {
      thead {
        th,
        td {
          padding: 0  15px 7px 0;
          &:first-child {
            padding-left: 7px;
          }
          &:last-child {
            padding-right: 7px;
          }
        }
      }
      tbody {
        th,
        td {
          padding: 5px 15px 5px 0;
          &:first-child {
            padding-left: 7px;
          }
          &:last-child {
            padding-right: 7px;
          }
        }
        td {
          line-height: 1.33;
        }
      }
      &--wide {
        tbody {
          th,
          td {
            padding: 10px 15px 10px 0;
          }
        }
      }
    }
  }

  .table_regis_comfirm{
    tbody {
      th{
        white-space: normal;
      }
      tr {
        &:nth-child(even) {
          background: transparent;
        }
      }
    }
  }


  .lc-tableWrapper  {
    overflow: auto;
  }

  //==========================================================
  // Step List
  //==========================================================
  .lc-stepList {
    display: flex;
    justify-content: center;
    font-size: 1rem;
    line-height: 1.125;
    text-align: center;
    &__item {
      margin: 40px 15px 0;
      position: relative;
      color: $c_text_gray;
      &::before,
      &::after {
        content: "";
        display: block;
        width: calc(50% + 15px);
        height: 1px;
        background: $c_text_gray;
        position: absolute;
        left: 50%;
        top: -20px;
        transform: translate(-100%, -50%);
        z-index: 2;
      }
      &::after {
        transform: translate(0, -50%);
      }
      &.is-current {
        color: $c_text;
        font-weight: bold;
        &::after {
          background: $c_border;
        }
        > .lc-stepList__icon {
          &::before {
            display: block;
            width: 32px;
            height: 32px;
            background: $c_base;
          }
          &::after {
            background: $cPink;
          }
        }
        ~ .lc-stepList__item {
          &::before,
          &::after {
            background: $c_border;
          }
          > .lc-stepList__icon {
            &::before {
              display: block;
              width: 20px;
              height: 20px;
              background: $c_base;
            }
            &::after {
              background: $c_text_gray2;
            }
          }
        }
      }
      &:first-child {
        &::before {
          display: none;
        }
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    &__icon {
      &::before,
      &::after {
        content: "";
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: -20px;
        z-index: 1;
        transform: translate(-50%, -50%);
      }
      &::before {
        display: none;
      }
      &::after {
        width: 8px;
        height: 8px;
        background: $c_text_gray;
        z-index: 3;
      }
    }
    &__label {
      white-space: nowrap;
    }
    @include media(tab) {
      position: relative;
      top: 40px;
      &__item {
        margin: 30px 0px 0;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
        &:nth-child(even) {
          span {
            bottom: 60px;
            position: relative;
          }
        }
      }
    }
  }

  //==========================================================
  // Paging Navi
  //==========================================================
  .lc-pagingNavi {
    font-size: 1.4rem;
    text-align: center;
    > em {
      font-size: 1.8rem;
      font-weight: bold;
    }
  }

  //==========================================================
  // Tab
  //==========================================================
  .lc-tab {
    max-width: 840px;
    margin: 0 auto 60px;
    &--form {
      max-width: 640px;
    }
    &__list {
      display: flex;
    }
    &__tab {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-grow: 1;
      height: 50px;
      border: solid 1px $cBlack;
      font-size: 1.8rem;
      font-weight: bold;
      cursor: pointer;
      transition: 0.3s;
      &:not(:last-child) {
        border-right: none;
      }
      &.is-active {
        border-color: $cBlack;
        background: $cBlack;
        color: $c_text_accent;
      }
      &:hover {
        opacity: 1;
        background: $c_text_gray;
        color: $c_base;
      }
    }
    &__panel {
      display: none;
      padding: 60px;
      border: solid 1px $cBlack;
      border-top: none;
      &.is-active {
        display: block;
      }
      .lc-productInfo {
        &:last-child {
          margin-bottom: 0;
          padding-bottom: 0;
          border-bottom: none;
        }
      }
    }
    @include media(tab) {
      margin: 0 auto 30px;
      &__tab {
        font-size: 1.6rem;
      }
      &__panel {
        padding: 20px 15px;
      }
    }
  }

  //==========================================================
  // Product Info
  //==========================================================
  .lc-productInfo {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-bottom: 30px;
    margin-bottom: 30px;
    border-bottom: dotted 1px $c_border;
    &__figure {
      width: 120px;
      margin-right: 30px;
      &__inner {
        display: block;
        width: 100%;
        padding-bottom: 100%;
        position: relative;
        overflow: hidden;
        > img {
          max-width: 100%;
          max-height: 100%;
          position: absolute;
          left: 50%;
          top: 50%;
          z-index: 0;
          transform: translate(-50%, -50%);
          .is-archiveFigureCovered & {
            max-width: none;
            width: auto;
            height: 100%;
            max-height: none;
            &.is-portrait {
              width: 100%;
              height: auto;
            }
          }
        }
      }
    }
    &__text {
      display: flex;
      align-items: flex-end;
      flex-grow: 1;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(100% - 150px);
    }
    &__name {
      width: 100%;
      line-height: 1.5;
    }
    &__review {
      display: flex;
      align-items: center;
      margin-top: 1em;
      > .lc-reviewsArchive {
        &__number {
          margin-right: 15px;
        }
      }
    }
    &__detail {
      &__item {
        display: flex;
        margin-top: 1em;
        font-size: 1.4rem;
        line-height: 1;
      }
      &__label {
        margin-right: 0.5em;
        &::after {
          content: ":";
        }
      }
      &__detail {
        font-weight: bold;
        > small {
          display: inline-block;
          margin-left: 0.5em;
          font-weight: normal;
        }
      }
    }
    &__controls {
      > span {
        display: inline-block;
        margin-right: 15px;
        &:last-child {
          margin-right: 0;
        }
      }
      .lc-selectBox {
        &__select {
          width: auto;
        }
      }
      .lc-button {
        &--edit,
        &--delete {
          margin-right: 15px;
          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
    &__additions {
      width: calc(100% - 150px);
      margin-left: 150px;
      &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 30px;
        margin-top: 30px;
        border-top: dotted 1px $c_border;
      }
      &__name {
        width: 100%;
        line-height: 1.5;
      }
    }
    + .lc-contentsInner__buttons {
      margin-bottom: 30px;
    }
    @include media(tab) {
      align-items: flex-start;
      padding-bottom: 20px;
      margin-bottom: 20px;
      &__figure {
        width: 90px;
        margin-right: 15px;
      }
      &__text {
        width: calc(100% - 105px);
      }
      &__review {
        > .lc-reviewsArchive {
          &__number {
            margin-right: 10px;
          }
        }
      }
      &__detail {
        &__item {
          font-size: 1.2rem;
        }
      }
      &__controls {
        margin-top: 15px;
      }
      &__additions {
        width: calc(100% - 105px);
        margin-left: 105px;
        &__item {
          padding-top: 20px;
          margin-top: 20px;
        }
      }
      + .lc-contentsInner__buttons {
        margin-bottom: 20px;
      }
    }
  }

  //==========================================================
  // Ship List
  //==========================================================
  .lc-shipList {
    padding: 25px 30px 30px;
    margin-bottom: 30px;
    background: $c_base;
    &__title {
      margin-bottom: .75em;
      font-size: 1.8rem;
      font-weight: bold;
      line-height: 1.25;
    }
    &__item {
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    &__box {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding: 12px 15px;
      border: solid 1px $c_border;
      background: $c_base;
      line-height: 1.5;
      transition: 0.3s;
      input:checked + & {
        border-color: $c_main;
      }
      &--button {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 50px;
        padding: 0 15px;
        color: $cBlack;
        > i {
          display: inline-block;
          margin-right: 10px;
        }
        @include media(tab-min) {
          transition: 0.3s;
          &:hover {
            border-color: $cBlack;
            opacity: 1 !important;
            color: $c_base;
            background-color: $c_text;
          }
        }
      }
    }
    &__main {
      flex-grow: 1;
      &:last-child {
        width: 100%;
      }
      .lc-productInfo {
        padding-bottom: 15px;
        margin-bottom: 15px;
        &:first-child {
          padding-top: 15px;
          margin-top: 10px;
          border-top: dotted 1px $c_border;
        }
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
        &__figure {
          width: 90px;
          margin-right: 15px;
        }
        &__additions {
          width: calc(100% - 105px);
          margin-left: 105px;
          &__item {
            padding-top: 15px;
            margin-top: 15px;
          }
        }
      }
    }
    &__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__tel {
      font-weight: bold;
    }
    &__address {
      padding-top: 10px;
      margin-top: 10px;
      border-top: dotted 1px $c_border;
      font-size: 1.4rem;
    }
    &__options {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      padding-top: 10px;
      margin-top: 10px;
      border-top: dotted 1px $c_border;
      &__item {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin: 5px 30px 5px 0;
        &:last-child {
          margin-right: 0;
        }
        &--single {
          width: 100%;
          margin-right: 0;
        }
      }
      &__label {
        margin-right: 15px;
      }
      &__detail {
        .lc-selectBox__select {
          width: auto;
        }
      }
    }
    &__info {
      padding-top: 10px;
      margin-top: 10px;
      border-top: dotted 1px $c_border;
      &__item {
        display: flex;
        margin-top: 0.5em;
        line-height: 1;
      }
      &__label {
        margin-right: 0.5em;
        &::after {
          content: ":";
        }
      }
      &__detail {
        font-weight: bold;
        > small {
          display: inline-block;
          margin-left: 0.5em;
          font-weight: normal;
        }
      }
    }
    &__controls {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 15px;
      > a,
      > button {
        margin-bottom: 10px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    @include media(tab) {
      padding: 12px 15px 15px;
      &__title {
        margin-bottom: 0.5em;
        font-size: 1.6rem;
      }
      &__main {
        .lc-productInfo {
          &__figure {
            width: 60px;
          }
          &__additions {
            width: calc(100% - 75px);
            margin-left: 75px;
          }
        }
      }
    }
  }


  //==========================================================
  // Review Stars
  //==========================================================
  .lc-reviewStars {
    display: flex;
    width: 6em;
    font-size: 1.4rem;
    @include media(tab-min) {
      &.is-changing {
        cursor: pointer;
        > span {
          filter: drop-shadow(0 3px 0 rgba(0, 0, 0, 0.25));
        }
      }
    }
    &--large {
      font-size: 2.1rem;
      + .lc-reviewStars__number {
        font-size: 2rem;
      }
    }
    > span {
      display: block;
      margin-right: 0.25em;
      width: 1em;
      height: 1em;
      background-image: url(../img/icon/review-star-empty.svg);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 100% auto;
      &:last-child {
        margin-right: 0;
      }
    }
    @for $i from 1 through 10 {
      $star_half: ceil(math.div($i, 2));
      $star_num: floor(math.div($i, 2));
      &[data-grade="#{$i}"] {
        > span {
          @if $star_num > 0 {
            &:nth-child(-n+#{$star_num}) {
              background-image: url(../img/icon/review-star-full.svg);
            }
          }
          @if ($i % 2 == 1) and ($star_half > 0) {
            &:nth-child(#{$star_half}) {
              background-image: url(../img/icon/review-star-half.svg);
            }
          }
        }
      }
    }
    &__number {
      margin-left: 10px;
      font-weight: bold;
    }
    @include media(tab) {
      &--large {
        font-size: 1.9rem;
        + .lc-reviewStars__number {
          font-size: 1.8rem;
        }
      }
    }
  }

  //==========================================================
  // Reviews Archive
  //==========================================================
  .lc-reviewsArchive {
    margin-bottom: 60px;
    &__header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 20px;
      line-height: 1;
    }
    &__title {
      margin-right: 0.75em;
      font-size: 2rem;
      font-weight: bold;
    }
    &__total {
      margin-right: 30px;
    }
    &__number {
      margin-left: 0.5em;
      color: $c_main;
      font-size: 2rem;
      font-weight: bold;
    }
    &__item {
      padding-bottom: 25px;
      margin-bottom: 25px;
      border-bottom: dotted 1px $c_border;
      font-size: 1.4rem;
    }
    &__reviewHeader {
      display: flex;
      margin-bottom: 20px;
      flex-direction: column;
      > .lc-reviewStars {
        margin-bottom: 10px;
      }
    }
    &__reviewTitle {
      font-weight: bold;
      line-height: 1.25;
      font-size: 1.2em;
    }
    &__nickname {
      display: flex;
      align-items: center;
      justify-content: end;
      margin-top: 20px;
      line-height: 1;
      @extend .lc-icon;
      @extend .lc-icon--user;
      &::before {
        padding: 3px;
        margin-right: 0.5em;
        border-radius: 50%;
        background: $c_text_gray;
        color: $c_base;
        font-size: 1.8rem;
      }
    }
    @include media(tab) {
      padding: 17px 15px 20px;
      margin: 0 0 30px;
      &__header {
        margin-bottom: 15px;
        width: 232px;        
      }
      &__title {
        font-size: 1.8rem;
      }
      &__total {
        margin-right: 15px;
      }
      &__number {
        font-size: 1.8rem;
      }
      &__item {
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      &__reviewHeader {
        > .lc-reviewStars {
          margin-right: 10px;
        }
      }
      &__detail {
        line-height: 1.5;
      }
      &__nickname {
        margin-top: 7px;
        &::before {
          padding: 2px;
          font-size: 1.6rem;
        }
      }
      .lc-contentSection > & {
        padding: 0;
        background: none;
      }
    }
  }

  //==========================================================
  // Mypage Navi
  //==========================================================
  .lc-mypageNavi {
    max-width: 840px;
    margin: 0 auto;
    &__title {
      margin-bottom: 1em;
      @extend .lc-heading--3;
    }
    &__subTitle {
      font-weight: bold;
      margin-bottom: 0.75em;
    }
    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 30px;
    }
    &__item {
      width: 33.33%;
      > a {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        min-height: 44px;
        padding: 0 15px;
        line-height: 1.25;
        border: solid 1px $c_border;
        border-left: none;
        border-top: none;
        @include media(tab-min) {
          &[href]:hover {
            opacity: 1;
            border-color: $c_text_gray;
            background: $c_text_gray;
            color: $c_base;
          }
        }
      }
      &:nth-child(-n+3) {
        > a {
          border-top: solid 1px $c_border;
        }
      }
      &:nth-child(3n+1) {
        > a {
          border-left: solid 1px $c_border;
        }
      }
    }
    @include media(tab) {
      &__item {
        width: 50%;
        > a {
          font-size: 1.4rem;
        }
        &:nth-child(-n+3) {
          > a {
            border-top: none;
          }
        }
        &:nth-child(3n+1) {
          > a {
            border-left: none;
          }
        }
        &:nth-child(-n+2) {
          > a {
            border-top: solid 1px $c_border;
          }
        }
        &:nth-child(2n+1) {
          > a {
            border-left: solid 1px $c_border;
          }
        }
      }
    }
    @include media(sp) {
      &__list {
        display: block;
      }
      &__item {
        width: auto;
        > a {
          border-left: solid 1px $c_border;
        }
        &:nth-child(-n+2),
        &:nth-child(3n+1) {
          > a {
            border-left: solid 1px $c_border;
          }
        }
        &:nth-child(2) {
          > a {
            border-top: none;
          }
        }
      }
    }
  }

  //==========================================================
  // Contents Frame
  //==========================================================
  .lc-contentsInner {
    width: calc(100% - 80px);
    max-width: 1286px;
    margin: 60px auto 30px;
    &--form {
      max-width: 640px;
    }
    &__buttons {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 30px;
      &--horizontal {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
        > .lc-button--submit {
          margin-right: 30px;
          margin-bottom: 0;
          &:last-child {
            margin-right: 0;
          }
        }
        > .lc-button--cancel {
          margin-top: 15px;
        }
      }
    }
    @include media(tab) {
      padding: 0;
      margin: 60px auto 15px;
      border: none;
      width: inherit;
      &__buttons {
        &--horizontal {
          > .lc-button--submit {
            margin-right: 15px;
          }
        }
      }
    }
    @include media(sp) {
      &__buttons {
        &--horizontal {
          flex-direction: column;
          > .lc-button--submit {
            margin-right: 0;
            margin-bottom: 15px;
          }
        }
      }
    }
    ul:not(form ul) {
      display: flex;
      flex-wrap: wrap;
      li{
        width: 100%;
      }
      li.lc-reviewsArchive__item__reviews {
        width: 48%;
        margin-right: 4%;
        padding: 1em;
        background-color: $c_base;
        border-bottom: none;
        &:nth-of-type(2n){
          margin-right: 0;
        }
        @include media(sp){
          width: 100%;
          margin-right: 0;
        }
      }
    }

  }

  .lc-contentSection {
    padding: 60px calc(50vw - 580px);
    &:first-of-type {
      padding-top: 0;
    }
    &__title {
      margin-bottom: 20px;
      font-size: 3.6rem;
      font-weight: bold;
      line-height: 1.25;
      &--center {
        text-align: center;
        + .blogArchive {
          margin-top: 60px;
        }
        + .lc-tab {
          margin-top: 60px;
        }
      }
    }
    &__note {
      margin-bottom: 30px;
      line-height: 1.5;
      &--large {
        font-size: 2rem;
      }
      &--center {
        text-align: center;
      }
      strong {
        color: $cRed;
        font-weight: bold;
      }
      small {
        font-weight: normal;
      }
    }
    &__more {
      margin-top: 60px;
      text-align: center;
    }
    &--strong {
      background: $c_main_bg;
      .lc-contentSection {
        &__title {
          color: $c_main;
        }
      }
    }
    &--gray {
      background: $c_base_gray;
    }
    @include media(1280px) {
      padding: 60px;
    }
    @include media(tab) {
      padding: 30px 10px;
      &__title {
        margin-bottom: 15px;
        font-size: 2rem;
        &--center {
          + .blogArchive {
            margin-top: 30px;
          }
        }
      }
      &__more {
        margin-top: 30px;
        text-align: center;
      }
    }
  }

  //==========================================================
  // select form of Chinese Address
  //==========================================================
  .lc-selectAddress {
    display: none;
    background: $c_base;
    border: solid 1px $c_border;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
    position: absolute;
    z-index: 11;
    &__bg {
      display: none;
      width: 100%;
      height: 100%;
      background: rgba(255, 255, 255, 0.01);
      position: fixed;
      left: 0;
      top: 0;
      z-index: 10;
    }
    &.is-active {
      display: block;
      + .lc-selectAddress__bg {
        display: block;
      }
    }
    &__tab {
      display: flex;
      &Item {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 32px;
        background: $c_base_gray;
        color: $c_text_gray;
        font-size: 14px;
        cursor: pointer;
        &[aria-selected="true"] {
          background: $c_base;
          color: $c_text;
        }
        &[aria-disabled="true"] {
          cursor: default;
          user-select: none;
        }
      }
    }
    &__list {
      padding: 15px;
      max-height: 320px;
      overflow: auto;
      &[aria-hidden="true"] {
        display: none;
      }
      &Item {
        line-height: 32px;
        cursor: pointer;
        &[aria-selected="true"] {
          color: $c_main;
        }
        @include media(tab-min) {
          &:hover {
            color: $c_main;
          }
        }
      }
    }
  }

  //==========================================================
  // Paging UI
  //==========================================================
  .lc-archiveGuide {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 60px;
    > .lc-pagingNavi {
      margin-bottom: 20px;
    }
    @include media(tab) {
      margin-top: 30px;
      > .lc-pagingNavi {
        margin-bottom: 15px;
      }
    }
    > .pagination_nav {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: center;
      font-size: 1.8rem;
      font-weight: bold;
      > a,
      > strong {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        height: 32px;
        margin: 0 10px;
        &.current {
          color: $c_main;
        }
      }
      > a {
        @include media(tab-min) {
          &:hover {
            color: $c_main;
            opacity: 1;
          }
        }
      }
      @include media(tab) {
        font-size: 1.6rem;
        > a,
        > strong {
          margin: 0 5px;
        }
      }
    }
  }

}

.lc-root .news_list.lc-contentsInner{
 max-width: 1180px;
 .newsArchive{
  display: flex;
  flex-wrap: wrap;
  li{
    width: 50%;
    margin: 20px 0;
    @include media(sp){
      width: 100%;
    }
    a{
      width: 90%;
      display: block;
      margin: 0 auto;
      border-bottom: solid 1px #bababa;
      .newsArchive__title{
        margin: 10px 0;
      }
      .blogArchive__figure{
        margin-top: 10px;
      }
    }
  }
 }
}

.lc-root .news_detail.lc-contentsInner{
  max-width: 860px;
  margin: 0 auto;
  width: 100%;
  padding: 0 20px;
  h4{
    font-size: 1.6rem;
    font-weight: bold;
    @include media(sp){
      font-size: 1.4rem;
    }
    &:before{
      content: "■";
      margin-right: 5px;
      display: inline-block;
      color: $c_main;
      font-size: 2rem;
    }
  }
  .pageArticle__title {
    margin-bottom: 10px;
    padding: 0.5em 0.75em;
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.5;
    color: $c_main;
    background-color: #e8f7ef;
    border-bottom: solid 2px $c_main;
  }
  .pageArticle p,
  .pageArticle figure,
  .pageArticle__info{
    margin-bottom: 10px;
  }
  .pageArticle h3{
    border-bottom: solid 1px $c_main;
    margin: 90px 0 40px;
    padding: 0.5em 0;
    font-size: 1.7rem;
    line-height: 1.59;
  }
  .l-special__goAarchive{
    width: 300px;
    margin: 0 auto;
    @include media(sp){
      width: 250px;
    }
    a{
      padding: 10px;
      width: 100%;
      display: block;
      margin: 40px auto;
      border: 1px solid $c_main;
      text-align: center;
      color: $c_main;
      &:hover{
        background: $c_main;
        border: 1px solid $c_main;
        color: #fff;
      }
    }
  }
}

.no-scroll{
 overflow-y: hidden;
}

.lc-root .lc-form__label--select.expire_month:before,
.lc-root .lc-form__label--select.expire_year:before{
  display: none;
}
.lc-root .lc-form__label--select.expire_month select,
.lc-root .lc-form__label--select.expire_year select{
  background: transparent;
  }
.expire_month,
.expire_year{
  width: 100px;
}
.expire_month select,
.expire_year select{
  width: 100%;
}
.expire_month .lc-form__title{
  width: 153px;
}
.lc-root .lc-form__label--select.expire_month p:before,
.lc-root .lc-form__label--select.expire_year p:before{
content: "\e904";
font-size: 1.6rem;
position: absolute;
right: 10px;
top: 53%;
transform: translate(0,-50%);
z-index: 1;
font-family: "lc-icons"!important;
}
.lc-form__detail__01{
 background-color: #fff;
}

.note_txt{
  margin-top: 15px;
  font-size: .7em;
}

.common_note{
  font-size: 1.2rem;
  margin-left: 10px;
}

.common_error{
  color: #ed5454;
  padding: 5px 13px;
  font-size: 1.4rem;
  @include media(sp){
    font-size: 1.2rem;
  }
}

// contact page
.contactForm {
  max-width: 840px;
  padding: 0 60px;
  margin: 60px auto 30px;
  text-align: left;
  @include media (sp) {
    padding: 0;
    margin: 60px auto 15px;
  }
  .error{
    display: block;
  }

  &__txt {
    margin-bottom: 20px;
  }

  dl {
    display: flex;
    justify-content: left;
    width: 100%;
    border-left: solid 1px #ccc;
    border-right: solid 1px #ccc;
    &:first-child {
      border-top: solid 1px #ccc;
      border-bottom: solid 1px #ccc;
    }
    &:not(:first-child) {
      border-bottom: solid 1px #ccc;
    }

    dt, dd {
      padding: 10px;
    }

    dt {
      width: 30%;
      border-right: solid 1px #ccc;
      font-weight: 500;
      background-color: #d1e8f2;
      color: #000;
      &.required {
        &::before {
          content: '*';
          color: red;
        }
      }
    }
    dd {
      width: 70%;
      
      input, textarea,select {
        width: 100%;
        padding: 0 10px;
        border: solid 1px #ccc;
        height: 42px;
      }
      select{
        width: auto;
        max-width: 100%;
      }
      textarea{
        padding: 10px;
      }
    }
  }

  table {
    table-layout: fixed;
    width: 100%;
    border: solid 1px #ccc;

    tr {
      th, td {
        padding: 10px;
      }

      th {
        width: 30%;
        font-weight: 500;
      }
      td {
        width: 70%;
      }
    }
  }

  .btnArea {
    margin-top: 40px;
    text-align: center;
    color: $c_main;
    font-weight: 500;

    button {
      &:not(:first-child) {
        margin-left: 20px;
      }
      color: #fff;
      padding: 10px 20px;
      background: #123573;
      min-width: 100px;
    }
  }
}

.contactThanks {
  text-align: center;

  h2 {
    font-size: 2.4rem;
    font-weight: 500;
    margin-bottom: 20px;
  }

  a {
    color: green;
    font-weight: 500;
    margin: 20px 0;
  }
}


